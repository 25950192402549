<template>
    <form class="form" action="https://formsubmit.co/601fb89196a0a426ae6ec9457fa139d8" method="post">
        <!-- formsubmit.co -->
        <input type="hidden" name="_url" value="https://mueller-transporte.ch">
        <input type="hidden" name="_next" value="https://mueller-transporte.ch/mail">
        <input type="hidden" name="_subject" value="mueller-transporte.ch | neue Nachricht">
        <!-- honeypot -->
        <input name="_formsubmit_id" type="text" style="display:none">

        <div class="container name">
            <input type="text" name="name" id="name" required="" placeholder="Name">
            <input type="text" name="vorname" id="firstname" placeholder="Vorname">
        </div>
        <div class="container tel email">
            <input type="tel" name="telefon" id="tel" placeholder="Telefon">
            <input type="email" name="email" id="email" required="true" placeholder="E-Mail">
        </div>

        <div class="container subject">
            <h2>zum Thema</h2>
            <ul class="vue-form-list">
                <li v-for="(feature, index) in features" :key="index">
                    <input type="checkbox"
                           :name="feature"
                           :value="'zum Thema ' + feature"
                           :id="'cb-feature-'+index"
                           v-model="selection.features">
                    <label :for="'cb-feature-'+index">{{feature}}</label>
                </li>
            </ul>

        </div>
        <div class="container text">
            <textarea class="message" name="Nachricht" id="textarea" required="" placeholder="Nachricht" rows="7"></textarea>
        </div>
        <div class="container submit">
            <input type="submit" value="senden">
        </div>

    </form>
</template>

<script>
    export default {
        name: "Kontaktformular",
        data() {
            return {
                features: ['Umzug', 'Transport', 'Räumung', 'Entsorgung', 'Einlagerung', 'anderes'],
                selection: {
                    member: "0",
                    framework: "vue",
                    features: [],
                },
                message: {
                    text: '',
                }
            }
        }
    }
</script>

<style scoped lang="scss">

    form {
        margin-left: auto;
        margin-right: auto;
        padding: $grid-gutter-width 0;
        max-width: 800px;

        .container {
            padding-left: 0;
            padding-right: 0;

            input,
            textarea {
                padding: 4px 12px;
                border: 2px solid $green--dark;
                border: 0;
                font-size: p2r(16);
                margin-bottom: 10px;
                //color: $green--dark;

                @include media-breakpoint-up(lg) {
                    font-size: p2r(20);
                }

                &::placeholder {
                    color: black;
                    color: $green--light;
                }
            }

            input[type="text"],
            input[type="email"],
            input[type="tel"],
            textarea {
                width: 100%;
            }

            @include media-breakpoint-up(md) {
                input[type="text"],
                input[type="email"],
                input[type="tel"] {
                    &:first-child {
                        width: calc(50% - 5px);
                        margin-right: 10px;
                    }

                    &:last-child {
                        width: calc(50% - 5px);
                    }
                }
            }

            &.subject {
                h2 {
                    margin-bottom: 0 !important;
                }

                ul {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    padding-left: 0;
                    padding: $grid-gutter-width / 2;

                    li {
                        list-style-type: none;
                        font-size: p2r(16);

                        @include media-breakpoint-up(lg) {
                            font-size: p2r(20);
                        }

                        input[type="checkbox"] {
                            display: none;
                        }

                        input[type="checkbox"] + label::before {
                            width: 15px;
                            height: 15px;
                            background-color: white;
                            display: inline-block;
                            content: "";
                            margin-right: 5px;
                        }

                        input[type="checkbox"]:checked {
                            & + label {
                                color: $green--dark;
                                color: black;

                                &::before {
                                    background-color: $green--dark;
                                    background-color: black;
                                }
                            }
                        }
                    }
                }
            }

            .counter {
                position: relative;
                top: -18px;
                left: 0;
                opacity: .7;
                color: black;
            }

            &.submit {
                input[type="submit"] {
                    width: 110px;
                    color: white;
                    background-color: $green--dark;
                    border-radius: 3px;
                    opacity: .8;
                    transition: opacity .5s ease;

                    &:hover {
                        opacity: .9;
                    }
                }
            }
        }
    }
</style>