export const data = {
    menu: {
        open: false
    },
    scroll: {
        theOffset: -90, // header-height
        scrollDuration: 700,
        menuAnimDuration: 400
    }
};
