<template>
    <div class="image colorchange" :class="nthChild">
        <picture>
            <source :srcset="getWebpUrl(img)" type="image/webp">
            <source :srcset="getJpgUrlFullsize(img)" type="image/jpeg">
            <img :src="getJpgUrlFullsize(img)" alt="szenebild">
        </picture>
    </div>
</template>

<script>
    export default {
        name: "TheImage",
        data() {
            return {
                nthChild: null
            }
        },
        props: {
            img: { type: String, required: true }
        },
        methods: {
            getJpgUrlFullsize(pic) {
                return require('../assets/images/' + pic + '.jpg')
            },

            getWebpUrl(pic) {
                return require('../assets/images/' + pic + '.webp')
            }
        },
    }
</script>

<style scoped lang="scss">
.image {
    font-size: 0; // without, display: block makes a space on bottom

    img {
        width: 100%;
        min-height: 300px;
        max-height: 700px;
        object-fit: cover;
    }
}
</style>