<template>
    <div>
        <div id="home">
            <the-image img="mood/calc/mood1"></the-image>
            <the-page name="Home" title="Herzlich willkommen bei der Müller Transporte GmbH aus Münsingen" img=""></the-page>
        </div>
        <div id="dienstleistungen">
            <the-page name="Umzuege" title="Umzüge — Zügeln" img="umzuege.svg" alt="Grafik Umzüge"></the-page>
            <the-page name="Transporte" title="Transporte" img="transporte.svg" alt="Grafik Transporte"></the-page>
            <the-page name="Raeumungen" title="Räumungen & Entsorgung" img="raeumungen.svg" alt="Grafik Räumungen"></the-page>
            <the-page name="Einlagerung" title="Einlagerung" img="einlagerung.svg" alt="Grafik Einlagerung"></the-page>
            <the-image img="mood/calc/mood2"></the-image>
        </div>
        <div id="preise">
            <the-page name="Preise" title="Preise" img=""></the-page>
        </div>
        <div id="team">
            <the-page name="TeamEinleitung" title="Ein starkes Team" img=""></the-page>
            <the-page name="TeamBilder" title="" img=""></the-page>
        </div>
        <div id="kontakt">
            <the-iframe>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2727.2794782555784!2d7.58066027669042!3d46.87755013803664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478e4a7c8eb5d19b%3A0xa2ea9a1333d06303!2sBahnhofstrasse%2020%2C%203111%20T%C3%A4gertschi!5e0!3m2!1sde!2sch!4v1701941313475!5m2!1sde!2sch" title="Karte Standort Mueller Transporte" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </the-iframe>
            <the-page name="Kontakt" title="Kontakt"></the-page>
            <the-page name="Kontaktformular" title="Kontaktanfrage"></the-page>
        </div>

    </div>
</template>

<script>
    import ThePage from '../components/ThePage'
    import TheImage from '../components/TheImage'
    import TheIframe from "../components/TheIframe";

    export default {
        name: "Main",
        data() {
            return {
                numChildren: 0,
                nthChange: ['odd', 'even'],
            }
        },
        components: {
            TheImage,
            ThePage,
            TheIframe,
        },
        mounted() {
            this.numChildren = this.$children.length;
            let p = 0;

            for(let i = 0; i < this.numChildren; i++) {
                this.$children[i].nthChild = this.nthChange[p];
                p++;
                if(p === this.nthChange.length) {
                    p = 0;
                }
            }
        }
    }
</script>

<style lang="scss">
    .colorchange {
        #home &,
        #kontakt &,
        #preise & {
            //color: black;

            .contact img {
                filter: $filter-green--dark;
            }
        }

        &.odd {
            background-color: $green--light;
            color: white;

            .icon img {
                filter: $filter-white;
            }
        }

        &.even {
            #dienstleistungen & {
                background-color: white;
                color: $green--dark;

                .icon img {
                    filter: $filter-green--dark;
                }
            }
        }
    }

</style>
