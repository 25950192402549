<template>
    <div>
        <p>Gerne erstellen wir eine unverbindliche Offerte für Ihren Auftrag. Schreiben Sie uns eine E-Mail oder rufen Sie uns an.</p>
        <table class="contact">
            <tr class="tel">
                <td><img class="icon" src="@/assets/icons/call.svg" alt="Müller Transporte anrufen"></td>
                <td><a href="tel:+41796684518">079 668 45 18</a></td>
            </tr>
            <tr class="mail">
                <td><img class="icon" src="@/assets/icons/mail.svg" alt="Grafik E-Mail"></td>
                <td><a href="mailto:mail@mueller-transporte.ch">mail@mueller-transporte.ch</a></td>
            </tr>
        </table>

        <h2>Lager</h2>
        <p>In unserem Lager in Tägertschi sind Ihre Möbel sicher. Die Kosten belaufen sich auf 14.— / m3 im Monat. Der Wert der eingestellten Gegenstände, muss durch Ihre Hausratversicherung abgedeckt sein.</p>

        <h3>Transportversicherung</h3>
        <p>Versicherungssummen</p>
        <table class="assurance-table">
            <tr>
                <td>70’000.—</td>
                <td>=</td>
                <td>40.—</td>
            </tr>
            <tr>
                <td>100’000.—</td>
                <td>=</td>
                <td>60.—</td>
            </tr>
            <tr>
                <td>140’000.—</td>
                <td>=</td>
                <td>80.—</td>
            </tr>
        </table>
        <p>u.s.w.</p>
        <br />
        <p>Bei einem Umzug oder einem Transport ist eine Versicherung unbedingt notwendig. Wir empfehlen eine Extra-Transportversicherung für Ihren Umzug, da die Hausratversicherung nur Objekte innerhalb Ihres Zuhauses deckt aber nicht während eines Transportes. Es ist auch möglich, bestimmte Objekte gesondert zu versichern.</p>
    </div>
</template>

<script>
    export default {
        name: "Preise"
    }
</script>

<style lang="scss">
    .contact {
        tr {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: $grid-gutter-width;

            td {

                &:first-child {
                    padding-right: 30px;

                    img {
                        height: 25px;
                    }
                }

                &:last-child {
                    width: 268px;
                }
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    .assurance-table {
        width: 100%;
        max-width: 250px;

        tr td:nth-child(2) {
            width: 70px;
        }

        @include media-breakpoint-up(lg) {
            max-width: 350px;

            tr td:nth-child(2) {
                width: 100px;
            }
        }
    }
</style>
