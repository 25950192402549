<template>
    <div>
        <p>Wir erledigen für Sie den kompletten Umzug und bieten Ihnen Transporte, Räumungen, Entsorgungen und Einlagerungen an. Bei Fragen zu unseren Dienstleistungen oder konkreten Projekten rufen Sie uns an oder schreiben Sie uns eine E-Mail, wir freuen uns auf eine Zusammenarbeit:</p>
        <div class="contact">
            <div class="tel">
                <img src="@/assets/icons/call.svg" alt="Telefon Müller Transporte">
                <a href="tel:+41796684518">079 668 45 18</a>
            </div>
            <div class="mail">
                <img src="@/assets/icons/mail.svg" alt="Grafik E-Mail">
                <a href="mailto:mail@mueller-transporte.ch">mail@mueller-transporte.ch</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Home"
    }
</script>

<style scoped lang="scss">
    .contact {
        margin: auto;

        div {
            padding-bottom: $grid-gutter-width;

            &:last-child {
                padding-bottom: 0;
            }
        }

        > div {
            width: fit-content;
            margin: auto;
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        img {
            width: 50px;
        }
    }
</style>
