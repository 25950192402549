<template>
    <div class="cards">
        <div class="item">
            <picture>
                <source srcset="../../assets/images/team/calc/square/team_manuel_pius_bacher_CAPTNS.webp" type="image/webp">
                <source srcset="../../assets/images/team/calc/square/team_manuel_pius_bacher_CAPTNS.jpg" type="image/jpeg">
                <img src="../../assets/images/team/calc/square/team_manuel_pius_bacher_CAPTNS.jpg" alt="Manuel Müller">
            </picture>
            <h2>Manuel Müller</h2>
            <p>Geschäftsführer + Inhaber</p>
            <p>Der Starke und Unerschöpfliche</p>
        </div>

        <div class="item">
            <picture>
                <source srcset="../../assets/images/team/calc/square/team_david_pius_bacher_CAPTNS.webp" type="image/webp">
                <source srcset="../../assets/images/team/calc/square/team_david_pius_bacher_CAPTNS.jpg" type="image/jpeg">
                <img src="../../assets/images/team/calc/square/team_david_pius_bacher_CAPTNS.jpg" alt="David Müller">
            </picture>
            <h2>David Müller</h2>
            <p>Geschäftsführer + Inhaber</p>
            <p>Der Ruhige und Vorsichtige</p>
        </div>

        <div class="item">
            <picture>
                <source srcset="../../assets/images/team/calc/square/team_bettina-pius_bacher_CAPTNS.webp" type="image/webp">
                <source srcset="../../assets/images/team/calc/square/team_bettina-pius_bacher_CAPTNS.jpg" type="image/jpeg">
                <img src="../../assets/images/team/calc/square/team_bettina-pius_bacher_CAPTNS.jpg" alt="Bettina Müller">
            </picture>
            <h2>Bettina Müller</h2>
            <p>Büroallrounderin</p>
            <p>Die Fleissige und Begabte im Büro</p>
        </div>
        <div class="item">
            <picture>
                <source srcset="../../assets/images/team/calc/square/team_remo_pius_bacher_CAPTNS.webp" type="image/webp">
                <source srcset="../../assets/images/team/calc/square/team_remo_pius_bacher_CAPTNS.jpg" type="image/jpeg">
                <img src="../../assets/images/team/calc/square/team_remo_pius_bacher_CAPTNS.jpg" alt="Remo Cordey">
            </picture>
            <h2>Remo Cordey</h2>
            <p>Mitarbeiter</p>
            <p>Der  Genaue und Zuverlässige</p>
        </div>
        <div class="item">
            <picture>
                <source srcset="../../assets/images/team/calc/square/team_angelina_pius_bacher_CAPTNS.webp" type="image/webp">
                <source srcset="../../assets/images/team/calc/square/team_angelina_pius_bacher_CAPTNS.jpg" type="image/jpeg">
                <img src="../../assets/images/team/calc/square/team_angelina_pius_bacher_CAPTNS.jpg" alt="Angelina Müller">
            </picture>
            <h2>Angelina Müller</h2>
            <p>Mitarbeiterin</p>
            <p>Die Einfühlsame und Hilfsbereite</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TeamBilder"
    }
</script>

<style lang="scss">
    .cards {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: $grid-gutter-width / 2;
        row-gap: $grid-gutter-width / 2;

        .item {
            display: grid; // new line per child element
            align-content: start;

            h2 {
                margin-top: $grid-gutter-width / 2 !important;
                margin-bottom: 0 !important;
            }

            img {
                width: 100%;
                height: auto;
            }

            p:first-of-type {
                margin-bottom: $grid-gutter-width / 2;
            };
        }

        @include media-breakpoint-up(sm) {
            grid-template-columns: 1fr 1fr;
            column-gap: $grid-gutter-width;
            row-gap: $grid-gutter-width;
        }

        @include media-breakpoint-up(md) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
</style>
