<template>
    <div>
        <p>Auch bei Räumungen unterstützen wir Sie gerne. Wir räumen, recyceln und entsorgen Altlasten in Ihren Wohnungen, Häusern, Lagerräumen, Estrichen, Kellern etc. Wir besprechen jedes Projekt mit Ihnen individuell. Egal ob Hausräumung, Wohnungsräumung, Wohnungsauflösung, bei einem Todesfall, Entrümpelungen oder Umzug ins Altersheim und anschliessender Reinigung – wir helfen Ihnen gerne.</p>
    </div>
</template>

<script>
    export default {
        name: "Raeumungen"
    }
</script>

<style scoped>

</style>