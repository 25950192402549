<template>
    <div>
        <address>
            Müller Transporte GmbH
            <br />
          Bahnhofstrasse 20
          <br />
          3111 Tägertschi
        </address>
        <br />
        <table class="contact">
            <tr class="tel">
                <td><img class="icon" src="@/assets/icons/call.svg" alt="Telefon"></td>
                <td><a href="tel:+41796684518" title="Telefon">079 668 45 18</a></td>
            </tr>
            <tr class="mail">
                <td><img class="icon" src="@/assets/icons/mail.svg" alt="E-Mail"></td>
                <td><a href="mailto:mail@mueller-transporte.ch" title="email">mail@mueller-transporte.ch</a></td>
            </tr>
        </table>
      <div class="ferien">
        <h2>Betriebsferien</h2>
        <p> Unser Geschäft bleibt vom Do. 19. bis zum So. 29. September 2024 geschlossen</p>
      </div>
    </div>
</template>

<script>
    export default {
        name: "Kontakt",
    }
</script>

<style scoped lang="scss">

    address {
        font-size: p2r(16);

        @include media-breakpoint-up(lg) {
            font-size: p2r(20);
        }
    }

    .contact {
        tr {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: $grid-gutter-width;

            td {

                &:first-child {
                    padding-right: 30px;

                    img {
                        height: 25px;
                    }
                }

                &:last-child {
                    width: 268px;
                }
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    .ferien {
        width: fit-content;
        margin-top: $grid-gutter-width;

        h2 {
            margin: $grid-gutter-width / 2 0 !important;
        }
    }

</style>
