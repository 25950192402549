<template>
    <div>
      <picture>
        <source srcset="../../assets/images/team/calc/square/team_mueller_transporte_pius_bacher_CAPTNS.webp" type="image/webp">
        <source srcset="../../assets/images/team/calc/square/team_mueller_transporte_pius_bacher_CAPTNS.jpg" type="image/jpeg">
        <img src="../../assets/images/team/calc/square/team_mueller_transporte_pius_bacher_CAPTNS.jpg" alt="Müller transporte team foto">
      </picture>
      <br/>
      <br/>
      <p>Die Müller Transporte GmbH ist ein Familienunternehmen. Seit 40 Jahren unterstützen wir Personen aus der Region und der ganzen Schweiz bei Ihren</p>
        <p>Umzügen — Transporten — Räumungen — Entsorgungen — Einlagerungen.</p>
    </div>
</template>

<script>
    export default {
        name: "Team"
    }
</script>

<style scoped>
  img {
    max-width: 100%;
  }
</style>
