<template>
    <div class="secondary-page">
        <h1>Ihre Nachricht ist angekommen. Vielen Dank!</h1>
        <router-link to="/">zurück zur Website</router-link>
    </div>
</template>

<script>
    export default {
        name: "MailConfirmation.vue"
    }
</script>

<style scoped>

</style>