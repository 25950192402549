<template>
    <div>
        <div id="fullscreen"></div>
        <div class="car-container" v-bind:style="{marginLeft: carPosition + 'px'}">
            <div id="drivebacklight" :class="{ show : this.scrollingBackward || this.movingDistance > 0 }">
                <img src="../assets/images/car/backlight_drive.png">
            </div>
            <div id="lights">
                <div class="backside"><img src="../assets/images/car/backlight.png"></div>
                <div class="frontside"><img src="../assets/images/car/frontlight.png"></div>
                <div class="inside"><img src="../assets/images/car/insidelight.png"></div>
            </div>
            <div class="car" @click="changeLights" @mousedown="theMouseDown" @mousemove="theMouseMove"></div>
            <div class="back wheel"><img src="../assets/images/car/wheel.png" :style="{ transform: 'rotate(' + wheelDeg + 'deg)' }"></div>
            <div class="front wheel"><img src="../assets/images/car/wheel.png" :style="{ transform: 'rotate(' + wheelDeg + 'deg)' }"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TheCar",
        data() {
            return {
                posY: 0,
                winHeight: 0,
                winWidth: 0,
                docHeight: '',
                perc: 0,
                carPos: 0,
                carWidth: 150,
                wheelDiameter: 16,
                wheelDeg: 0,
                clicked: false,
                wasMoving: false,
                mousePosReference: 0,
                movingDistance: 0,
                carPosReference: 0,
                scrollingBackward: false,
            }
        },
        mounted() {
            window.addEventListener('scroll', this.myScroll)
            window.addEventListener('scroll', this.wheelRotation)
            window.addEventListener('mouseup', this.theMouseUp)
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.myScroll)
            window.removeEventListener('scroll', this.wheelRotation)
        },
        computed: {
            carPosition() {
                return this.carPos
            }
        },
        methods: {
            myScroll() {
                let theScrollTimer = -1;
                this.winHeight = document.getElementById('fullscreen').offsetHeight;
                this.winWidth = document.getElementById('fullscreen').offsetWidth;
                this.docHeight = document.getElementById('app').scrollHeight

                if (theScrollTimer !== -1) {
                    clearTimeout(theScrollTimer)
                }

                if(this.clicked === false) {
                    if (this.posY > window.scrollY) {
                        this.scrollingBackward = true;
                    } else {
                        this.scrollingBackward = false
                    }
                    //this.posY = window.scrollY;
                    // wrong height, don't know why //
                    //this.winHeight = window.outerHeight;
                    //this.winWidth = window.outerWidth;

                    this.perc = 100 * this.posY / (this.docHeight - this.winHeight);
                    this.posY = window.scrollY;
                    this.carPos = (this.winWidth - this.carWidth) / 100 * this.perc;
                } else {
                    this.perc = this.carPos * 100 / (this.winWidth - this.carWidth);
                    this.posY = this.perc * (this.docHeight - this.winHeight) / 100;
                    document.documentElement.scrollTop = this.posY;
                }

                theScrollTimer = window.setTimeout(() => { this.scrollingBackward = false }, 500);

                /*
                console.log('posY: ' + this.posY + ', winHeight: ' + this.winHeight + ', docHeight: ' + this.docHeight);
                console.log(this.perc);
                console.log('winWidth: ' + this.winWidth);
                console.log('percWidth: ' + this.carPos);
                console.log('carPos: ' + this.carPos);
                 */

            },

            changeLights() {
                // do not toggle when moving the car, only on click
                if (this.wasMoving === false) {
                    document.getElementById('lights').classList.toggle('show')
                }
            },

            finished() {
                console.log(this.scrollingBackward);
                this.scrollingBackward = 'notanymore'
            },

            wheelRotation() {
                let wheelRange = this.wheelDiameter * 3.14;
                let totalDeg = this.winWidth / wheelRange * 360;
                this.wheelDeg = totalDeg / 100 * this.perc;
            },

            theMouseDown(e) {
                this.clicked = true;
                this.mousePosReference = e.clientX;
                this.carPosReference = this.carPos
            },

            theMouseMove(e) {
                if(this.clicked) {
                    this.movingDistance = this.mousePosReference - e.clientX;
                    this.carPos = this.carPosReference - this.movingDistance;
                }

                this.myScroll()
            },

            theMouseUp() {
                this.clicked = false;
                if (this.movingDistance !== 0) {
                    this.wasMoving = true;
                    this.carPosReference = 0;
                    this.mousePosReference = 0;
                    this.movingDistance = 0;
                } else {
                    this.wasMoving = false
                }
            }
        },

    }
</script>

<style scoped lang="scss">

    #fullscreen {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        z-index: -1;
    }

    .car-container {
        display: none;
        position: fixed;
        width: 150px;
        height: 70px;
        bottom: 0;
        left: 0;

        .car {
            background-image: url('../assets/images/car/car.png');
            background-size: contain;
            height: 100%;
        }

        @include media-breakpoint-up(md) {
            display: block;
        }

        img {
            width: 100%;
            height: auto;
        }

        #drivebacklight {
            position: absolute;
            width: 17px;
            margin-top: 31px;
            margin-left: -3px;
            opacity: 0;
            transition: opacity .5s ease;

            &.show {
                opacity: 1;

            }
        }

        #lights {
            position: absolute;
            opacity: 0;
            transition: opacity 0.5s ease;

            &.show {
                opacity: 1;
            }

            .backside {
                position: absolute;
                margin-left: -128px;
                margin-top: -7px;
                width: 141px;
                opacity: 0.8;
            }

            .frontside {
                position: absolute;
                margin-left: 135px;
                width: 230px;
                margin-top: -3px;
            }

            .inside {
                position: absolute;
                width: 9px;
                margin-left: 118px;
                margin-top: 19px;
            }
        }

        .wheel {
            position: absolute;
            z-index: 10;
            width: 16px;
            bottom: -2px;

            &.back {
                left: 15px;
            }

            &.front {
                right: 10px;
            }
        }
    }

</style>