<template>
    <footer>
        <div>
            <router-link to="/" @click.native="scrollToTop">Müller Transporte GmbH</router-link>
            <router-link to="/impressum">Impressum & Disclaimer</router-link>
        </div>
    </footer>
</template>

<script>
    import VueScrollTo from "vue-scrollto";

    export default {
        name: "TheFooter",
        methods: {
            scrollToTop() {
                VueScrollTo.scrollTo('#app');

                if(this.data.menu.open === true) {
                    this.data.menu.open = false
                }
            },
        }
    }
</script>

<style scoped lang="scss">

    footer {
        background-color: $green--dark;
        padding: 0 $grid-gutter-width;

        div {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: auto;
            width: 100%;
            max-width: $max-width;

            & > * {
                &:first-child {
                    padding-right: 50px;
                }
            }

            a {
                margin: $grid-gutter-width 0;
                color: white;
                transition: color 0.5s ease;
                z-index: 50;

                &:first-child {
                    display: none;
                }

                &:hover {
                    color: $green--light;
                    text-decoration: none;
                }
            }

            @include media-breakpoint-up(md) {
                a {
                    &:first-child {
                        display: block;
                    }

                    &:last-child {
                        padding-right: 250px;
                    }
                }

            }
        }
    }

</style>