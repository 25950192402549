<template>
    <div>
        <p>Wir kümmern uns effizient und unkompliziert um Ihren Umzug. Nebst dem professionellen Transport Ihrer Einrichtung, bieten wir Ihnen alles, was Sie für einen erfolgreichen Umzug benötigen, wie zum Beispiel Transportkisten, Verpackungsmaterial, Demontage und Montage aller Art, Möbel sowie die Endreinigung Ihrer Wohnung. Wir sind bemüht, Ihren Umzug reibungslos und positiv zu gestalten.</p>
    </div>
</template>

<script>
    export default {
        name: "Umzuege"
    }
</script>

<style scoped>

</style>