<template>
    <div class="iframe colorchange" :class="nthChild">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "TheIframe",
        data() {
            return {
                nthChild: null
            }
        }
    }
</script>

<style scoped lang="scss">
    .iframe {
        line-height: 0;

        iframe {
            width: 100%;
            //filter: grayscale();
            filter: grayscale(100%);
        }
    }

</style>