<template>
    <div>
        <p>In unserem Lager in Tägertschi sind Ihre Möbel sicher. Nach telefonischer Absprache haben Sie immer Zugriff auf Ihre Gegenstände!</p>
    </div>
</template>

<script>
    export default {
        name: "Einlagerung"
    }
</script>

<style scoped>

</style>
