<template>
    <div id="parent-head">
        <header>
            <div class="logo">
                <router-link to="/" @click.native="scrollToTop">
                    <picture>
                        <source type="image/svg+xml" srcset="../assets/images/logo.svg">
                        <img src="../assets/images/logo.png" alt="logo">
                    </picture>
                </router-link>
            </div>
            <div class="heading">
                <router-link to="/" @click.native="scrollToTop">
                    <div class="title">
                        <h1>Müller Transporte</h1>
                        <small>Münsingen</small>
                    </div>
                </router-link>
                <div class="subtitle">
                    <p>Umzüge — Transporte — Räumungen — Entsorgung — Einlagerung</p>
                </div>
            </div>

            <the-hamburger @toggle_menu="toggleMenu"></the-hamburger>
        </header>
        <transition
            name="menu"
            >
            <nav v-if="this.data.menu.open" @click="toggleMenu" @scroll="scrollMenu">
                <div class="main">
                    <router-link to="/#dienstleistungen" class="hvr-grow">Dienstleistungen</router-link>
                    <router-link to="/#preise" class="hvr-grow">Preise</router-link>
                    <router-link to="/#team" class="hvr-grow">Team & Über uns</router-link>
                    <router-link to="/#kontakt" class="hvr-grow">Kontakt</router-link>
                </div>
                <div class="sub">
                    <router-link to="/impressum" class="hvr-grow">Impressum & Disclaimer</router-link>
                </div>
            </nav>
        </transition>
    </div>
</template>

<script>

    import VueScrollTo from "vue-scrollto";
    import TheHamburger from "../components/TheHamburger";
    import { data } from "../store/store";

    export default {
        name: "TheHeader",
        components: {
            TheHamburger
        },

        data() {
            return {
                data: {}
            }
        },

        mounted() {
            this.$nextTick(() => {
                window.addEventListener('load', () => {
                    if(this.data.menu.open) {
                        this.getHeaderHeight();
                        this.theMenuSize()
                    }
                });

                window.addEventListener('resize', () => {
                    if(this.data.menu.open) {
                        this.getHeaderHeight();
                        this.theMenuSize()
                    }
                });
            })
        },

        created() {
            this.data = data
        },

        methods: {
            getHeaderHeight() {
                this.data.scroll.headerHeight = document.querySelector('header').offsetHeight * (-1);
            },

            scrollToTop() {
                VueScrollTo.scrollTo('#app');

                if(this.data.menu.open === true) {
                    this.data.menu.open = false
                }
            },

            scrollMenu() {
                this.getHeaderHeight();
                this.theMenuSize();
            },

            toggleMenu() {
                this.data.menu.open = !this.data.menu.open;
            },

            theMenuSize() {
                let menuContainer = document.querySelector('nav');
                let menuMainContent = document.querySelector('nav .main');
                let menuSubContent = document.querySelector('nav .sub');
                let menuHeightTot = menuMainContent.offsetHeight + menuSubContent.offsetHeight;
                const menuPadding = 120;

                /*
                console.log('menuContainer: ' + menuContainer.offsetHeight);
                console.log('menuMainContent: ' + menuMainContent.offsetHeight);
                console.log('menuSubContent: ' + menuSubContent.offsetHeight);
                console.log('both: ' + menuHeightTot);
                 */

                if(menuContainer.offsetHeight < (menuHeightTot + menuPadding)) {
                    menuContainer.classList.add('block');
                } else {
                    if(menuContainer.classList.contains('block')) {
                        menuContainer.classList.remove('block')
                    }
                }
            }
        }
    }
</script>

<style scoped lang="scss">

    #parent-head {
        position: fixed;
        top: 0;
        z-index: 99;
    }

    // The Header
    header {
        z-index: 99;
        position: fixed;
        height: $header-height-sm;
        display: flex;
        align-items: center;
        background-color: $green--dark;
        color: white;
        padding: 15px 0;
        width: 100%;

        /*
        @include media-breakpoint-up(md) {
            height: $header-height-md;
        }

         */

        .logo {
            align-self: normal;
            padding: 0 10px;
            margin-top: -10px;
            z-index: 99;

            img {
                position: relative;
                width: 40px;
                z-index: 100;
            }
        }

        .heading {
            display: flex;
            flex-direction: column;

            a {
                color: white;

                &:hover {
                    text-decoration: none;
                    color: white;
                }
            }

            .title {
                display: flex;
                flex-direction: row;

                h1 {
                    font-family: SuisseBold, SuisseLight, sans-serif;
                    margin: 0;
                    font-size: p2r(18);
                }

                small {
                    display: none;
                    font-family: SuisseBold, SuisseLight, sans-serif;
                    margin: 0;
                }
            }

            .subtitle {
                display: none;

                p {
                    margin: 0;
                }
            }
        }

        @media ($up-xs) {
            // from that size on, the heading doesn't wrap

            .logo {
                margin-top: -5px;

                img {
                    width: 55px;
                }
            }

            .heading {
                .title h1 {
                    font-size: p2r(20);
                }

                .title small {
                    display: block;
                    font-size: p2r(7);
                }

                .subtitle {
                    display: block;

                    p {
                        font-size: p2r(5);
                    }
                }
            }
        }


        @include media-breakpoint-up(sm) {
            height: $header-height-md;

            .logo {
                padding: 0 $grid-gutter-width/2;

                img {
                    width: 110px;
                }
            }

            .heading {
                .title h1 {
                    font-size: p2r(30)
                }

                .title small {
                    margin-left: 5px;
                    font-size: p2r(10);
                }

                .subtitle {
                    p {
                        font-size: p2r(8);
                    }
                }
            }
        }

        @include media-breakpoint-up(lg) {
            height: $header-height-xl;

            .logo {
                padding: 0 $grid-gutter-width;

                img {
                    width: 155px;
                }
            }

            .heading {
                .title h1 {
                    font-size: p2r(60);
                }

                .title small {
                    font-size: p2r(17);
                }

                .subtitle p {
                    font-size: p2r(16);
                }
            }
        }
    }

    // The open Navigation
    nav {
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background: $green--light;
        overflow: auto;
        height: calc(100vh - #{$header-height-sm});
        top: $header-height-sm;
        transition: all .2s;
        width: 100vw;

        @include media-breakpoint-up(sm) {
            height: calc(100vh - #{$header-height-md});
            top: $header-height-md;
        }

        @include media-breakpoint-up(lg) {
            height: calc(100vh - #{$header-height-xl});
            top: $header-height-xl;
        }

        .main,
        .sub {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transition: opacity .3s;
            opacity: 1;

            a {
                transition: all .3s;
                opacity: 1;
                color: white;
                text-decoration: none;
            }
        }

        .main {
            a {
                font-size: p2r(30);

                @include media-breakpoint-up(sm) {
                    font-size: 7vw;
                }

                @include media-breakpoint-up(lg) {
                    font-size: p2r(60);
                }
            }
        }

        .sub {
            a {
                font-size: p2r(16);

                @include media-breakpoint-up(md) {
                    font-size: 3vw
                }

                @include media-breakpoint-up(lg) {
                    font-size: p2r(30);
                }
            }
        }

        // Correct Scroll anim, when Nav should scroll
        &.block {
            display: block;

            .main,
            .sub {
                padding: $grid-gutter-width;
            }
        }

        // the Open/Close animation
        &.menu-leave-active {
            transition-delay: .5s;

            a {
                transition-delay: 0s;
            }
        }

        &.menu-enter,
        &.menu-leave-to {
            height: 0;

            a {
                opacity: 0;
            }
        }
    }

</style>