<template>
    <div @click="onToggle" class="toggler">
        <button :class="burgerAnimToggle" class="hamburger hamburger--spin" type="button"
                aria-label="Menu" aria-controls="navigation" :aria-expanded="ariaExpandedToggle">
            <span class="hamburger-box">
                <span class="hamburger-inner"></span>
            </span>
        </button>
    </div>
</template>

<script>
    import { data } from "../store/store";

    export default {
        name: "TheHamburger",
        data() {
            return {
                data: {},
            }
        },
        created() {
            this.data = data;
        },
        computed: {
            ariaExpandedToggle() {
                if (this.data.menu.open === true) {
                    return 'true'
                } else {
                    return 'false'
                }
            },

            burgerAnimToggle() {
                if (this.data.menu.open === true) {
                    return 'is-active'
                } else {
                    return ''
                }
            }
        },
        methods: {
            onToggle() {
                this.$emit('toggle_menu');
            },
        }
    }
</script>

<style lang="scss">
    .toggler {
        margin-left: auto;

        button {
            line-height: 0;
            padding: $grid-gutter-width / 2;

            &:focus {
                outline: 0;
            }

            .hamburger-box {
                width: 20px;

                .hamburger-inner,
                .hamburger-inner::before,
                .hamburger-inner::after {
                    width: 20px;
                }
            }

            @include media-breakpoint-up(sm) {
                transform: scale(1.5);
                padding: $grid-gutter-width;
            }

            @include media-breakpoint-up(md) {
                transform: scale(1.8);
                padding: $grid-gutter-width;
            }
        }
    }

</style>