<template>
    <article class="colorchange" :class="nthChild">
        <slot></slot>

        <div class="icon" v-if="img">
            <img :src="getImgUrl(img)" :alt="alt">
        </div>
        <h1 v-if="title">{{ title }}</h1>
        <component :is="name"></component>
    </article>
</template>

<script>
    import * as components from './pages/index'

    export default {
        name: "ThePage",
        data() {
            return {
                nthChild: null
            }
        },
        components: {
            ...components
        },
        props: {
            name: { type: String, required: true },
            title: { type: String, required: false },
            img: { type: String, required: false },
            alt: { type: String, required: false }
        },
        methods: {
            getImgUrl(pic) {
                return require('../assets/icons/' + pic)
            },
        }
    }
</script>

<style scoped lang="scss">
    article {
        margin: auto;
        padding: $grid-gutter-width;

        & > * {
            max-width: $max-width;
            margin: auto;
        }

        .icon {
            width: 100%;
            max-width: $max-width;
            padding-bottom: $grid-gutter-width;

            img {
                margin-left: 0;
                max-width: 120px;
                max-height: 65px;
            }
        }
    }

</style>