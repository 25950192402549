<template>
  <div id="app" @scroll="onScroll">
    <the-header></the-header>
    <div id="parent-content">
      <router-view></router-view>
    </div>
    <the-footer></the-footer>
    <the-car v-if="isHome"></the-car>
  </div>
</template>

<script>
  import { data } from './store/store';
  import TheHeader from './components/TheHeader';
  import TheFooter from "./components/TheFooter";
  import TheCar from "./components/TheCar";

  export default {
    name: 'App',
    components: {
      TheHeader,
      TheFooter,
      TheCar
    },
    data() {
      return {
        data: {},
        scroll: 0,
        isHome: true,
      }
    },
    created() {
      document.documentElement.setAttribute('lang', 'de');
      this.data = data
    },
    mounted() {
      window.addEventListener('load', this.getHeaderHeight());
      window.addEventListener('resize', () => {
        this.getHeaderHeight()
      });
    },
    watch: {
      '$route.path': function() {
        this.onRouteChange()
      }
    },
    methods: {
      onScroll () {
        this.scroll = window.scrollY
      },
      onRouteChange () {
        if(this.$route.matched[0].components.default.name === 'Main') {
          this.isHome = true
        } else {
          this.isHome = false
        }
      },
      getHeaderHeight() {
        if (window.innerWidth <= 575) {
          this.data.scroll.theOffset = -50
        } else if (window.innerWidth <= 992) {
          this.data.scroll.theOffset = -90
        } else {
          this.data.scroll.theOffset = -120
        }
        //console.log(window.innerWidth)
      },
    },
    head: {
      title: {
        inner: 'Müller Transporte GmbH aus Münsingen'
      },
      meta: [
        { name: 'description', content: 'Die Müller Transporte GmbH aus Münsingen. Das Familienunternehmen für Umzüge, Transporte, Räumungen, Entsorgung und Einlagerung' },
        { name: 'google-site-verification', content: 'H9VX6BXKyHA5_nNuWPk-HeS7o1P6UP5vnbeJeXW3icA' }
      ]
    }
  }
</script>

<style lang="scss">
  @font-face {
    font-family: SuisseLight;
    src:
      url("./assets/fonts/SuisseIntl-Light-Web/S/SuisseIntl-Light-WebS.woff") format("woff"),
      url("./assets/fonts/SuisseIntl-Light-Web/S/SuisseIntl-Light-WebS.woff2") format("woff2");
    font-display: swap;
  }

  @font-face {
    font-family: SuisseLightItalic;
    src:
      url("./assets/fonts/SuisseIntl-LightItalic-Web/S/SuisseIntl-LightItalic-WebS.woff") format("woff"),
      url("./assets/fonts/SuisseIntl-LightItalic-Web/S/SuisseIntl-LightItalic-WebS.woff2") format("woff2");
    font-display: swap;
  }

  @font-face {
    font-family: SuisseBold;
    src:
      url("./assets/fonts/SuisseIntl-Bold-Web/S/SuisseIntl-Bold-WebS.woff") format("woff"),
      url("./assets/fonts/SuisseIntl-Bold-Web/S/SuisseIntl-Bold-WebS.woff2") format("woff2");
    font-display: swap;
  }

  body {
    margin: 0;

    #app {
      position: absolute;
      width: 100%;
      top: 0;
      font-family: SuisseLight, Helvetica, Arial, sans-serif;
      text-align: left;
      color: #2c3e50;

      #parent-content {
        margin-top: $header-height-sm;
        position: relative;
        width: 100%;
        min-height: calc(100vh - #{$header-height-sm + $footer-height});

        @include media-breakpoint-up(sm) {
          margin-top: $header-height-md;
          min-height: calc(100vh - #{$header-height-md + $footer-height});
        }

        @include media-breakpoint-up(lg) {
          margin-top: $header-height-xl;
          min-height: calc(100vh - #{$header-height-xl + $footer-height});
        }

        a {
          color: inherit;
          text-decoration: none;
          transition: color 0.5s ease;

          &[href^="mailto:"],
          &[href^="tel:"] {
            font-size: p2r(22);
            padding-top: 5px;
          }

          &:hover {
            color: $green--dark;
            text-decoration: none;
          }
        }

        p,
        td {
          font-size: p2r(16);

          @include media-breakpoint-up(lg) {
            font-size: p2r(20);
          }
        }

        h1 {
          font-size: p2r(28);
          margin-bottom: $grid-gutter-width;
          hyphens: auto;

          @include media-breakpoint-up(md) {
            font-size: p2r(32);
            hyphens: none;
          }
        }

        h2 {
          font-size: p2r(24);
          margin: $grid-gutter-width 0;
          hyphens: auto;

          @include media-breakpoint-up(md) {
            font-size: p2r(28);
            hyphens: none;
          }
        }

        div {
          p:last-child {
            margin-bottom: 0; // achtung! kann ausnahmen geben - mit content überprüfen
          }
        }

        .secondary-page {
          padding: $grid-gutter-width;
          max-width: $max-width;
          margin-left: auto;
          margin-right: auto;

          @include media-breakpoint-up(md) {
            padding: $grid-gutter-width * 2 $grid-gutter-width;
          }
        }
      }
    }
  }

</style>
