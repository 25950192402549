import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'
import Router from 'vue-router'
import VueHead from 'vue-head'

import MainPage from '../views/Main'
import ImpressumPage from '../views/Impressum'
import MailConfirmation from "../views/MailConfirmation";
import { data } from '../store/store'

Vue.use(VueScrollTo, {
    duration: data.scroll.scrollDuration,
    easing: 'ease-out',
    offset: data.scroll.theOffset
})

Vue.use(Router)

Vue.use(VueHead)

export default new Router({
    mode: 'history',
    scrollBehavior (to) {
        if (to.hash) {
            setTimeout(() => {
                VueScrollTo.scrollTo(to.hash);
                return {selector: to.hash}
            }, data.scroll.menuAnimDuration)
        } else if (to.path !== '/') {
            return { x: 0, y: 0 }
        }
    },
    routes: [
        {
            path: '/',
            component: MainPage
        },
        {
            path: '/#preise',
            component: MainPage
        },
        {
            path: '/#dienstleistungen',
            component: MainPage
        },
        {
            path: '/#team',
            component: MainPage
        },
        {
            path: '/#kontakt',
            component: MainPage
        },
        {
            path: '/impressum',
            component: ImpressumPage
        },
        {
            path: '/mail',
            component: MailConfirmation
        },
        {
            path: '*',
            component: MainPage
        }
    ]
})