<template>
    <div>
        <p>Wir übernehmen für Sie Transporte jeglicher Art, transportieren einzelne Möbelstücke (auch die schweren), für Ihre Firma das gesamte Büroinventar und diverse andere kleine oder grössere Transporte, ganz nach Ihren Wünschen.</p>
    </div>
</template>

<script>
    export default {
        name: "Transporte.vue"
    }
</script>

<style scoped>

</style>
